<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " data-bs-bg="../../../public/assets/img/pageTitle/uretim.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ production.heading }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="anasayfa" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li>{{ production.heading }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ltn__about-us-area pb-115">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 align-self-center">
            <div class="about-us-img-wrap ltn__img-shape-left  about-img-left">
              <img :src="production.img" :alt="production.heading">
            </div>
          </div>
          <div class="col-lg-7 align-self-center">
            <div class="about-us-info-wrap">
              <div class="section-title-area ltn__section-title-2--- mb-20">
                <h1 class="section-title">{{ production.heading }}</h1>
                <p>
                  {{ production.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import iso from "../../axios";
export default {
  props:['langcontent'],
  data() {
    return {
      production: [],
    }
  },
  created() {
    iso.get('production')
        .then(response => {
          this.production = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>